<template>
  <el-main>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="客户信息" width="200" align="center">
        <template v-slot="{ row }">
          <div class="user-info">
            <el-image :src="row.avatar ? row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png '"></el-image>
            <span>{{ row.name || '--' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="gender" label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.gender == 0 ? '未知' : row.gender == 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time)}}
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
import { getDateformat } from '../../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      total_number: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.user.wxwork.staff_member, {
          user_id: this.$route.query.user_id,
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .user-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    span {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>